/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* Define the scrollbar track for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar, adjust as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #a6d9f0; /* Blue color for the scrollbar thumb, adjust as needed */
  border-radius: 1.5px; /* Set the border radius, adjust as needed */
}
.custom-scroll-container {
}
/* Define the scrollbar track for WebKit browsers (Chrome, Safari) */
.custom-scroll-container::-webkit-scrollbar {
  width: 15px; /* Set the width of the scrollbar */
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the track */
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set the border radius of the thumb */
  border: 5px solid transparent; /* Create a border effect if needed */
}

body {
  font-family: "Sora", sans-serif;
  background-color: rgba(230, 242, 247, 0.3);
  zoom: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* For fixing ipad input opacity issue  */
input:disabled,
input[disabled] {
  background-color: inherit !important;
}
textarea:disabled,
textarea[disabled] {
  background-color: inherit !important;
}

@layer utilities {
  /* For print invoice */
  .no-print {
    display: none;
  }

  @media print {
    .no-print {
      display: block;
    }
    .printZoom {
      zoom: 50%;
    }
    @page {
      margin: 0mm; /* Adjust as needed */
    }

    body {
      margin: 5mm;
    }

    /* Add custom footer */
    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 10mm; /* Adjust the padding as needed */
    }

    .page-content {
      padding-bottom: 50px; /* Adjust based on footer height */
    }
  }
  /* For print invoice end */

  .topindex {
    z-index: 9999 !important;
  }
  .scrollbar-hidden::-webkit-scrollbar {
    width: 1px; /* Width of the scrollbar */
  }

  .scrollbar-hidden::-webkit-scrollbar-thumb {
    background-color: #000cf900; /* Color of the thumb (the draggable part of the scrollbar) */
  }
  /* Heading Styles */
  .text-headingBB,
  .text-headingRB,
  .text-headingLB {
    font-size: 20px;
    line-height: 25.2px;
  }

  .text-headingBB {
    font-weight: 600;
  }

  .text-headingRB {
    font-weight: 400;
  }

  .text-headingLB {
    font-weight: 200;
  }

  /* Heading Extra Bold Styles */
  .text-heading2EB,
  .text-heading2B,
  .text-heading2R,
  .text-heading2L {
    font-size: 18px;
    line-height: 23px;
  }

  .text-heading2EB {
    font-weight: 700;
  }

  .text-heading2B {
    font-weight: 600;
  }

  .text-heading2R {
    font-weight: 400;
  }

  .text-heading2L {
    font-weight: 300;
  }

  /* Body Bold Styles */
  .text-bodyEBB,
  .text-bodyBB,
  .text-bodyRB,
  .text-bodyLB {
    font-size: 16px;
    line-height: 20.16px;
  }

  .text-bodyEBB {
    font-weight: 700;
  }

  .text-bodyBB {
    font-weight: 600;
  }

  .text-bodyRB {
    font-weight: 400;
  }

  .text-bodyLB {
    font-weight: 200;
  }

  /* Body Extra Bold Styles */
  .text-bodySEBB,
  .text-bodySBB,
  .text-bodySRB,
  .text-bodySLB {
    font-size: 14px;
    line-height: 18px;
  }

  .text-bodySEBB {
    font-weight: 700;
  }

  .text-bodySBB {
    font-weight: 600;
  }

  .text-bodySRB {
    font-weight: 400;
  }

  .text-bodySLB {
    font-weight: 200;
  }

  /* Small Bold Styles */
  .text-smallBB,
  .text-smallRB,
  .text-smallLB {
    font-size: 12px;
    line-height: 15.12px;
  }

  .text-smallBB {
    font-weight: 600;
  }

  .text-smallRB {
    font-weight: 400;
  }

  .text-smallLB {
    font-weight: 200;
  }

  /* Media query for smaller screens */
  @media screen and (max-width: 1300px) {
    /* Heading Styles */
    .text-headingBB,
    .text-headingRB,
    .text-headingLB {
      font-size: 17px;
      line-height: 25.2px;
    }

    .text-headingBB {
      font-weight: 600;
    }

    .text-headingRB {
      font-weight: 400;
    }

    .text-headingLB {
      font-weight: 200;
    }

    /* Heading Extra Bold Styles */
    .text-heading2EB,
    .text-heading2B,
    .text-heading2R,
    .text-heading2L {
      font-size: 15px;
      line-height: 23px;
    }

    .text-heading2EB {
      font-weight: 700;
    }

    .text-heading2B {
      font-weight: 600;
    }

    .text-heading2R {
      font-weight: 400;
    }

    .text-heading2L {
      font-weight: 300;
    }

    /* Body Bold Styles */
    .text-bodyEBB,
    .text-bodyBB,
    .text-bodyRB,
    .text-bodyLB {
      font-size: 13px;
      line-height: 20.16px;
    }

    .text-bodyEBB {
      font-weight: 700;
    }

    .text-bodyBB {
      font-weight: 600;
    }

    .text-bodyRB {
      font-weight: 400;
    }

    .text-bodyLB {
      font-weight: 200;
    }

    /* Body Extra Bold Styles */
    .text-bodySEBB,
    .text-bodySBB,
    .text-bodySRB,
    .text-bodySLB {
      font-size: 12px;
      line-height: 18px;
    }

    .text-bodySEBB {
      font-weight: 700;
    }

    .text-bodySBB {
      font-weight: 600;
    }

    .text-bodySRB {
      font-weight: 400;
    }

    .text-bodySLB {
      font-weight: 200;
    }

    /* Small Bold Styles */
    .text-smallBB,
    .text-smallRB,
    .text-smallLB {
      font-size: 10px;
      line-height: 15.12px;
    }

    .text-smallBB {
      font-weight: 600;
    }

    .text-smallRB {
      font-weight: 400;
    }

    .text-smallLB {
      font-weight: 200;
    }
  }
  /* Media query for smaller screens */
  @media screen and (max-width: 760px) {
    /* Heading Styles */
    .text-headingBB,
    .text-headingRB,
    .text-headingLB {
      font-size: 11px;
      line-height: 25.2px;
    }

    .text-headingBB {
      font-weight: 600;
    }

    .text-headingRB {
      font-weight: 400;
    }

    .text-headingLB {
      font-weight: 200;
    }

    /* Heading Extra Bold Styles */
    .text-heading2EB,
    .text-heading2B,
    .text-heading2R,
    .text-heading2L {
      font-size: 12px;
      line-height: 23px;
    }

    .text-heading2EB {
      font-weight: 700;
    }

    .text-heading2B {
      font-weight: 600;
    }

    .text-heading2R {
      font-weight: 400;
    }

    .text-heading2L {
      font-weight: 300;
    }

    /* Body Bold Styles */
    .text-bodyEBB,
    .text-bodyBB,
    .text-bodyRB,
    .text-bodyLB {
      font-size: 12px;
      line-height: 20.16px;
    }

    .text-bodyEBB {
      font-weight: 700;
    }

    .text-bodyBB {
      font-weight: 600;
    }

    .text-bodyRB {
      font-weight: 400;
    }

    .text-bodyLB {
      font-weight: 200;
    }

    /* Body Extra Bold Styles */
    .text-bodySEBB,
    .text-bodySBB,
    .text-bodySRB,
    .text-bodySLB {
      font-size: 11px;
      line-height: 18px;
    }

    .text-bodySEBB {
      font-weight: 700;
    }

    .text-bodySBB {
      font-weight: 600;
    }

    .text-bodySRB {
      font-weight: 400;
    }

    .text-bodySLB {
      font-weight: 200;
    }

    /* Small Bold Styles */
    .text-smallBB,
    .text-smallRB,
    .text-smallLB {
      font-size: 6px;
      line-height: 15.12px;
    }

    .text-smallBB {
      font-weight: 600;
    }

    .text-smallRB {
      font-weight: 400;
    }

    .text-smallLB {
      font-weight: 200;
    }
  }

  .rounded-navbar {
    border-radius: 3px, 0px, 0px, 3px;
  }
}

.react-datepicker__year-select {
  padding-left: 12px;
  width: 80px;
  height: 25px;
  outline: none;
  border-radius: 10%;
}
.react-datepicker__month-select {
  width: 100px;
  height: 25px;
  outline: none;
  margin-top: 2px;
  border-radius: 10%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px;
  color: #000;
}

.react-datepicker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
  font-family: "Sora", sans-serif !important;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--light) !important;
  background: white !important;
}

.react-datepicker__current-month {
  color: var(--dark) !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  border: solid 2px var(--brand) !important;
  background-color: white !important;
  color: var(--dark) !important;
  width: 30px;
  height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 7px;
  background-color: linear-gradient(140.26deg, #219fd9 10.32%, #0081bc 97.42%);
  color: white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: none;
  border-radius: 7px !important;
  background-color: var(--brand) !important;
  color: var(--dark) !important;
}

.animate-in {
  /* Define your animation styles here */
  animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
